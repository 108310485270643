
::v-deep {
.product-overview {
    .product-overview-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        @media screen and (min-width: 1225px) {
            flex-direction: row;
            margin-bottom: 56px;
        }
    }

    .label {
        width: 100%;
        margin-bottom: 16px;
        padding-right: 0;

        font-family: "SF Pro Text"
        font-style: normal
        font-weight: 600
        font-size: 24px;
        color: #121212;
        line-height: 30px;

        @media screen and (min-width: 1225px) {
            width: 40%;
            padding-right: 162px;
            margin-bottom: 0;
        }
    }

    .detail {
        width: 100%;

        @include title-regular;

        color: #121212;
        line-height: 22px;

        @media screen and (min-width: 1225px) {
            width: 60%;
        }
    }

    .sub-detail {
        display: flex;
        width: 100%;
    }

    .sub-detail-description {
        width: 50%;
        margin-bottom: 20px;

        &:first-child {
            margin-right: 16px;
        }
    }
}
}
